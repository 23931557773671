import React from "react";
import { graphql, Link } from "gatsby";
import { GraphQLResponse } from "../models";
import { Layout } from "../layouts";
import ProjectGrid from "../components/projects/ProjectGrid";
import PageHeader from "../components/core/PageHeader";
import { Container } from "../components/core/Container/styled";

interface ProjectsProps {
  title: string;
  description: string;
  bannerImage: {
    image: string;
    focalPoint: string;
  };
}

interface ProjectsPageProps extends GraphQLResponse<ProjectsProps> {}

const ProjectsPage = (props: ProjectsPageProps) => {
  const {
    title,
    description,
  } = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
  return (
    <Layout title={"Projects"}>
      <PageHeader title={title} description={description} textAlign="center" />
      <Container>
        <ProjectGrid
          isFilterable={true}
          projects={props.data.allMarkdownRemark.edges}
        />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        name: { eq: "projects" }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              description
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { layout: { eq: "project" } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            location
            tags
            thumbnail
          }
        }
      }
    }
  }
`;

export default ProjectsPage;
