import React from "react";
import { useInView } from "react-hook-inview";
import { Wrapper, ImageBackground } from "./styled";
import { fade } from "../../../transition";

interface ProjectGridItemProps {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ProjectGridItem = ({ to, children, style }: ProjectGridItemProps) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
    threshold: 0.25,
  });
  return (
    <Wrapper
      exit={{
        length: 0.25,
        trigger: ({ exit, node }) => fade({ exit, node, direction: "out" }),
      }}
      entry={{
        length: 0.25,
        trigger: ({ exit, node }) => fade({ exit, node, direction: "in" }),
        delay: 0.25,
      }}
      isVisible={isVisible}
      to={to}
      innerRef={ref}
    >
      <ImageBackground style={{ ...style }} />
      {children}
    </Wrapper>
  );
};

export default ProjectGridItem;
