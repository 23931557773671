import styled from "styled-components";
import TransitionLink from "gatsby-plugin-transition-link";
import {
  Space,
  Color,
  Typography,
  Easing,
  Weight,
  Breakpoints,
  Timing,
} from "../../../settings";
import { breakpointDown, breakpointUp } from "../../../util/breakpoint";
import { LINK_UNDERLINE_STYLE } from "../../core/Navigation/styled";

interface WrapperProps {
  isVisible: boolean;
}

export const ProjectGridDetails = styled.div`
  width: 75%;

  color: ${Color.black};
  text-align: center;
  z-index: 5;
`;

export const ProjectGridTitle = styled.h3`
  padding: 0 ${Space.large.rem};
  margin: 0;

  font-size: ${Typography.large.rem};
  font-weight: ${Weight.regular};
  transform: translateY(${Space.large.rem});
  opacity: 0;
  transition: opacity ${Easing.slow}, transform ${Easing.default};
  transition-delay: 75ms;
`;

export const ProjectGridLocation = styled.p`
  margin-top: ${Space.default.rem};
  margin-bottom: 0;
  display: inline-block;

  transform: translateY(${Space.large.rem});
  text-align: center;
  opacity: 0;
  transition: opacity ${Easing.slow}, transform ${Easing.default};
  transition-delay: 0;

  ${LINK_UNDERLINE_STYLE}
`;

export const ImageBackground = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-size: cover;
  transition: transform ${Easing.xSlow};
  transform: scale(1);
`;

export const Wrapper = styled(TransitionLink)<WrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 40vh;
  min-height: 360px;
  width: calc(50% - ${Space.default.rem});
  margin-top: ${Space.large.rem};

  text-decoration: none;
  background-color: ${Color.concrete};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  transform: ${p =>
    p.isVisible ? `translateY(0)` : `translateY(${Space.default.rem})`};
  transition: opacity ${Easing.xSlow}, transform ${Easing.slow};

  ${breakpointDown(Breakpoints.tabletPortrait.lower)`
    width: 100%;
  `}

  &:nth-child(odd) {
    ${breakpointUp(Breakpoints.tabletPortrait.upper)`
      margin-right: ${Space.default.rem};
    `}
  }

  &:nth-child(even) {
    ${breakpointUp(Breakpoints.tabletPortrait.upper)`
      margin-left: ${Space.default.rem};
    `}
  }

  ${breakpointUp(Breakpoints.tabletPortrait.upper)`
    height: 30vw;
    &:nth-child(-n + 2) {
      margin-bottom: 0;
    }
  `}

  &:after {
    content: "";
    display: block;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${Color.lightBuff};
    opacity: 0;
    transition: opacity ${Easing.xSlow};
  }

  &:hover {
    ${ProjectGridTitle},
    ${ProjectGridLocation} {
      opacity: 1;
      transform: translateY(0);
    }

    ${ProjectGridTitle} {
      transition-delay: 0;
    }

    ${ProjectGridLocation} {
      transition-delay: 150ms;
      &:after {
        transform: scaleX(1);
        transition-delay: 250ms;
        opacity: 1;
      }
    }

    ${ImageBackground} {
      transform: scale(1.025);
    }

    &:after {
      opacity: 0.9;
    }
  }
`;
