import styled from "styled-components";
import { Color, Space, Typography, Weight } from "../../../settings";
import { LINK_UNDERLINE_STYLE } from "../../core/Navigation/styled";
import TransitionLink from "gatsby-plugin-transition-link";

export const ProjectGridWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ProjectGridTitle = styled.h2`
  margin: 0;

  margin-bottom: ${Space.large.rem};

  font-size: ${Typography.large.rem};
  font-weight: ${Weight.regular};
`;

export const ProjectGridViewMore = styled(TransitionLink)`
  position: relative;
  display: inline-block;
  margin-top: ${Space.xxLarge.rem};

  color: ${Color.black};
  text-decoration: none;
  font-weight: ${Weight.regular};
  font-size: ${Typography.large.rem};

  ${LINK_UNDERLINE_STYLE}
`;
