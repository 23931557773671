import React from "react";
import { ProjectType, ProjectFilterType } from "../../../types";
import {
  Filter,
  FilterLabel,
  FilterList,
  FilterItem,
  FilterSelect,
  FilterArrow,
  FilterSelectWrapper,
} from "./styled";
import { FILTER_LABEL_MAPPING } from "../../../mapping";
import MediaQuery from "react-responsive";
import { createBreakpoint } from "../../../util/createBreakpoint";

interface FilterProps {
  filters: ProjectType[];
  setActiveFilter: (actieFilter: ProjectFilterType) => void;
  activeFilter: ProjectFilterType;
}

export const FILTER_BREAKPOINT = createBreakpoint(768);

const Filters = ({ filters, activeFilter, setActiveFilter }: FilterProps) => (
  <>
    <MediaQuery maxWidth={FILTER_BREAKPOINT.lower}>
      <FilterSelectWrapper>
        <FilterSelect
          onChange={e => setActiveFilter(e.target.value as ProjectFilterType)}
        >
          {["all" as ProjectFilterType, ...filters].map(filter => (
            <option value={filter}>{FILTER_LABEL_MAPPING[filter]}</option>
          ))}
        </FilterSelect>
        <FilterArrow> &#9660;</FilterArrow>
      </FilterSelectWrapper>
    </MediaQuery>
    <MediaQuery minWidth={FILTER_BREAKPOINT.upper}>
      <FilterList>
        {["all" as ProjectFilterType, ...filters].map(filter => (
          <FilterItem key={`filter-${filter}`}>
            <Filter
              id={`filter-${filter}`}
              type="radio"
              checked={activeFilter === filter}
              onChange={() => setActiveFilter(filter)}
            />
            <FilterLabel htmlFor={`filter-${filter}`}>
              {FILTER_LABEL_MAPPING[filter]}
            </FilterLabel>
          </FilterItem>
        ))}
      </FilterList>
    </MediaQuery>
  </>
);

export default Filters;
