import styled from "styled-components";
import { Color, Space, Easing, Typography } from "../../../settings";

export const FilterList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 0.75rem;

  margin: 0 auto;
  margin-bottom: ${Space.large.rem};
  padding: 0;
`;

export const FilterItem = styled.li`
  list-style: none;
  line-height: 100%;
`;

export const Filter = styled.input`
  display: none;
`;

export const FilterLabel = styled.label`
  display: inline-block;

  padding: ${Space.small.rem};
  width: 100%;

  background-color: ${Color.concrete};
  cursor: pointer;
  text-align: center;
  line-height: 100%;
  transition: background-color ${Easing.default};

  ${Filter}:checked + & {
    background-color: ${Color.lightBuff};
  }

  &:hover {
    background-color: ${Color.darkConcrete};
  }
`;

export const FilterSelectWrapper = styled.div`
  position: relative;
`;

export const FilterSelect = styled.select`
  appearance: none;
  -webkit-appearance: none;

  width: 100%;
  padding: ${Space.default.rem};

  background-color: ${Color.lightBuff};
  border: none;
  border-radius: 0;
`;

export const FilterArrow = styled.span`
  position: absolute;

  top: 50%;
  right: ${Space.default.rem};
  font-size: ${Typography.default.rem};

  transform: scaleY(0.9) translateY(-40%);
`;
