import React, { useState } from "react";
import Filters from "../Filters";
import { ProjectType, ProjectFilterType } from "../../../types";
import { ProjectGridWrapper } from "./styled";
import ProjectGridItem from "../ProjectGridItem";
import {
  ProjectGridTitle,
  ProjectGridDetails,
  ProjectGridLocation,
} from "../ProjectGridItem/styled";
import Image from "../../modules/Image";
import SmallImage from "../../modules/SmallImage";

interface ProjectGridProps {
  projects: {
    node: {
      fields: {
        slug: string;
      };
      frontmatter: {
        title: string;
        location?: string;
        tags: ProjectType[];
        thumbnail: string;
      };
    };
  }[];
  isFilterable: boolean;
}

const ProjectGrid = ({ projects, isFilterable }: ProjectGridProps) => {
  const [activeFilter, setActiveFilter] = useState<ProjectFilterType>("all");
  const tags = [
    ...new Set(
      projects
        .map((project) => project.node.frontmatter.tags)
        .reduce((prev, current) => prev.concat(current), [])
    ),
  ];

  const activeProjects = projects.filter((project) => {
    if (
      activeFilter !== "concept" &&
      project.node.frontmatter.tags?.includes("concept")
    ) {
      return false;
    }

    return activeFilter === "all" || !isFilterable
      ? true
      : project.node.frontmatter.tags?.includes(activeFilter);
  });

  const transitionKey = activeProjects
    .map((project) => project.node.fields.slug)
    .reduce((a, b) => `${a}${b}`, ``);
  return (
    <>
      {isFilterable && (
        <Filters
          filters={tags}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
        />
      )}
      <ProjectGridWrapper key={transitionKey}>
        {activeProjects.map((project) => (
          <ProjectGridItem
            key={project.node.fields.slug}
            to={project.node.fields.slug}
          >
            <SmallImage
              image={project.node.frontmatter.thumbnail}
              altText="d"
              focalPoint="0,0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
            <ProjectGridDetails>
              <ProjectGridTitle>
                {project.node.frontmatter.title}
              </ProjectGridTitle>
              {!!project.node.frontmatter.location && (
                <ProjectGridLocation>
                  {project.node.frontmatter.location}
                </ProjectGridLocation>
              )}
            </ProjectGridDetails>
          </ProjectGridItem>
        ))}
      </ProjectGridWrapper>
    </>
  );
};

export default ProjectGrid;
